import React, { useEffect } from 'react';
import {
  Link, Outlet,
} from "react-router-dom";

const importScript = src => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [src])
}

const SiteDirectAdminResellerComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "DirectAdmin Reseller Hosting";
	importScript("/assets/SiteTheme/js/designesia.js");

	return (
        <div className="no-bottom no-top" id="content">
            <div id="top"></div>

            <section id="subheader" className="jarallax">
                <img src="/assets/SiteTheme/images/background/subheader.jpg" className="jarallax-img" alt="" />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>DirectAdmin Reseller Hosting</h1>
                                </div>
                                <div className="col-lg-6 offset-lg-3">
                                    <p className="lead">Get more resources for less money with our DirectAdmin reseller plans.</p>
<span className="badge bg-primary">Use coupon code HPNEW to only pay $1.00 for your first month when ordering a monthly plan!</span>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>

            <section id="section-hero" className="no-top mt-100" aria-label="section">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <div className="switch-set">
                                <div>Monthly Plan</div>
                                <div><input id="sw-1" className="switch" type="checkbox" /></div>
                                <div>Yearly Plan</div>                               
                            </div>
                            <div className="spacer-10"></div> 
                        </div>
                    </div>
                    <div className="spacer-10"></div>

                    <div className="row de_pricing-tables shadow-soft g-0">
                        <div className="col-xl-3 col-lg-6">
                            <div className="de_pricing-table type-2">
                                <div className="d-head">
                                    <h3>Roto Plan</h3>
                                    <p>Ideal solution for beginners.</p>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$15<span>/mo</span></h4>
                                    <h4 className="opt-2">$14<span>/mo</span></h4>
                                </div>
                                <div className="d-action">
									<a href="https://clients.hostpallet.com/index.php?rp=/store/directadmin-reseller-hosting/roto-plan" className="btn-main w-100">Purchase Plan</a>
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
										<li>15 DirectAdmin Accounts</li>
                                        <li>100 GB NVMe Storage</li>
										<li>500 GB Bandwidth</li>
										<li>Unlimited Email Accounts</li>
										<li>Unlimited Subdomains</li>
										<li>Unlimited FTP Accounts</li>
										<li>Unlimited MySQL Databases</li>
										<li>Supports PHP 8.1-8.3</li>
										<li>24/7 Customer Support</li>
										<li>2 CPU Cores/Account</li>
										<li>2 GB Memory/Account</li>
										<li>5 MB/s IO/Account</li>
										<li>500,000 Inodes/Account</li>
                                    </ul>
                                </div>
                                <div className="d-group">
                                    <h4>Also Includes</h4>
                                    <ul className="d-list">
                                        <li>Free Backups</li>
                                        <li>Free SSL Certificates</li>
                                        <li>Free Migration Assistance</li>
										<li>Softaculous Integration</li>
										<li>CloudLinux Integration</li>
										<li>JetBackup Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="de_pricing-table type-2">
                                <div className="d-head">
                                    <h3>Reflection Plan</h3>
                                    <p>For those need to running multiple sites.</p>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$20<span>/mo</span></h4>
                                    <h4 className="opt-2">$19<span>/mo</span></h4>
                                </div>
                                <div className="d-action">
									<a href="https://clients.hostpallet.com/index.php?rp=/store/directadmin-reseller-hosting/reflection-plan" className="btn-main w-100">Purchase Plan</a>
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
										<li>25 DirectAdmin Accounts</li>
                                        <li>150 GB NVMe Storage</li>
										<li>900 GB Bandwidth</li>
										<li>Unlimited Email Accounts</li>
										<li>Unlimited Subdomains</li>
										<li>Unlimited FTP Accounts</li>
										<li>Unlimited MySQL Databases</li>
										<li>Supports PHP 8.1-8.3</li>
										<li>24/7 Customer Support</li>
										<li>2 CPU Cores/Account</li>
										<li>2 GB Memory/Account</li>
										<li>5 MB/s IO/Account</li>
										<li>1,000,000 Inodes/Account</li>
                                    </ul>
                                </div>
                                <div className="d-group">
                                    <h4>Also Includes</h4>
                                    <ul className="d-list">
                                        <li>Free Backups</li>
                                        <li>Free SSL Certificates</li>
                                        <li>Free Migration Assistance</li>
										<li>Softaculous Integration</li>
										<li>CloudLinux Integration</li>
										<li>JetBackup Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6">
                            <div className="de_pricing-table type-2">
                                <div className="d-recommend">Recommended</div>
                                <div className="d-head">
                                    <h3>Block Plan</h3>
                                    <p>Large number of DirectAdmin accounts for webmasters.</p>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$25<span>/mo</span></h4>
                                    <h4 className="opt-2">$24<span>/mo</span></h4>
                                </div>
                                <div className="d-action">
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/directadmin-reseller-hosting/block-plan" className="btn-main w-100">Purchase Plan</a>
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
										<li>45 DirectAdmin Accounts</li>
                                        <li>200 GB NVMe Storage</li>
										<li>2 TB Bandwidth</li>
										<li>Unlimited Email Accounts</li>
										<li>Unlimited Subdomains</li>
										<li>Unlimited FTP Accounts</li>
										<li>Unlimited MySQL Databases</li>
										<li>Supports PHP 8.1-8.3</li>
										<li>24/7 Customer Support</li>
										<li>2 CPU Cores/Account</li>
										<li>3 GB Memory/Account</li>
										<li>10 MB/s IO/Account</li>
										<li>1,000,000 Inodes/Account</li>
                                    </ul>
                                </div>
                                <div className="d-group">
                                    <h4>Also Includes</h4>
                                    <ul className="d-list">
                                        <li>Free Backups</li>
                                        <li>Free SSL Certificates</li>
                                        <li>Free Migration Assistance</li>
										<li>Softaculous Integration</li>
										<li>CloudLinux Integration</li>
										<li>JetBackup Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6">
                            <div className="de_pricing-table type-2">
                                <div className="d-head">
                                    <h3>Stringer Plan</h3>
                                    <p>Best performance with optimized CPU resources.</p>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$30<span>/mo</span></h4>
                                    <h4 className="opt-2">$29<span>/mo</span></h4>
                                </div>
                                <div className="d-action">
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/directadmin-reseller-hosting/stringer-plan" className="btn-main w-100">Purchase Plan</a>
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
										<li>60 DirectAdmin Accounts</li>
                                        <li>250 GB NVMe Storage</li>
										<li>4 TB Bandwidth</li>
										<li>Unlimited Email Accounts</li>
										<li>Unlimited Subdomains</li>
										<li>Unlimited FTP Accounts</li>
										<li>Unlimited MySQL Databases</li>
										<li>Supports PHP 8.1-8.3</li>
										<li>24/7 Customer Support</li>
										<li>2 CPU Cores/Account</li>
										<li>3 GB Memory/Account</li>
										<li>15 MB/s IO/Account</li>
										<li>1,000,000 Inodes/Account</li>
                                    </ul>
                                </div>
                                <div className="d-group">
                                    <h4>Also Includes</h4>
                                    <ul className="d-list">
                                        <li>Free Backups</li>
                                        <li>Free SSL Certificates</li>
                                        <li>Free Migration Assistance</li>
										<li>Softaculous Integration</li>
										<li>CloudLinux Integration</li>
										<li>JetBackup Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <section id="section-steps" className="no-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <h5 className="s2">Easy Steps</h5>
                        <h2>Features that brings maximum power to your website.</h2>
                        <div className="spacer-20"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <i className="icon-alarmclock de-icon mb20"></i>
                        <h4>Instant Activation</h4>
                        <p>Experience the speed of instant activation: launch your services instantly and seamlessly!</p>
                    </div>
                    <div className="col-md-3">
                        <i className="icon-refresh de-icon mb20"></i>
                        <h4>99.9% Server Uptime</h4>
                        <p>Server uptime for uninterrupted online presence and maximum business continuity.</p>
                    </div>
                    <div className="col-md-3">
                        <i className="icon-layers de-icon mb20"></i>
                        <h4>Dallas, TX Data Center</h4>
                        <p>Host your sites on the backbone of the internet.</p>
                    </div>
                    <div className="col-md-3">
                        <i className="icon-cloud de-icon mb20"></i>
                        <h4>LiteSpeed Web Server</h4>
                        <p>All sites are served through LiteSpeed for fastest possible performance.</p>
                    </div>
                </div>
            </div>
        </section>



            <section>
                <div className="container">
                    <div className="row g-custom-x">
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-alarmclock"></i>
                                <div className="text">
                                    <h4>DirectAdmin Control Panel</h4>
Our hosting platform uses the well known DirectAdmin control panel. Easily manage email accounts, databases, FTP accounts, cron entries, work in the terminal and more from it's familiar interface. DirectAdmin is available upon request.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-profile-male"></i>
                                <div className="text">
                                    <h4 className="">SSL and Backups</h4>
									Free SSL certificates are available with all plans. SSL Certificates are a great way to ensure traffic between your site and users are handled secretly.
									We also offer JetBackup so you can view the backups available and restore from them.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-refresh"></i>
                                <div className="text">
                                    <h4>24/7 Support Team</h4>
									We have staff to help you no matter the issue. Having an issue using DirectAdmin, tracking/fixing strange PHP errors, setting up email or a wide variety of other problems? Our experts are available 24/7 for help right away!
                                </div>
                            </div>
                        </div>

 
                         <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-tools-2"></i>
                                <div className="text">
                                    <h4>450+ One-Click Scripts for Install</h4>
									Using Softaculous, you can easily install over 450 applications within a minute. Easily install WordPress, Joomla, Drupal and PrestaShop.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-layers"></i>
                                <div className="text">
                                    <h4 className="">Free Website Migrations</h4>
									Our migration team will migrate all of your sites to HostPallet.com for you at no charge. All
you need to do is open a ticket with our support team.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-shield"></i>
                                <div className="text">
                                    <h4>Satisfaction Guaranteed</h4>
									Not happy with the quality of our services? We provide all new clients with a 30 day money back guarantee. No need to stress if our service will work for you.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="section-features" className="no-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <h2>Additional Features</h2>
                            <div className="spacer-20"></div>
                        </div>
                    </div>
                    <div className="row g-custom-x">
                            <div className="col-md-4 offset-md">
                                <ul className="ul-style-2 s2">                
                                    <li><h4>CloudLinux</h4>Limits accounts system resources, to ensure your sites are not impacted by others on the server.</li>
                                    <li><h4>Imunify360</h4>All files are automatically scanned for viruses and malware.</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="ul-style-2 s2">  
                                    <li><h4>PHP Selector</h4>Select from multiple PHP versions across your sites/subdomains with ease.</li>
                                    <li><h4>JetBackup</h4>JetBackup manages all backups across all our servers, to remote storage servers, allowing for customers to download account backups at any time.</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="ul-style-2 s2">  
                                    <li><h4>LiteSpeed</h4>The most powerful web server available in the hosting industry, to ensure your sites load as quickly as possible.</li>
                                    <li><h4>LSCache</h4>An integral part of LiteSpeed, responsible for the parsing of PHP pages, responsible for faster website loading.</li>
                                </ul>
                            </div>
                        </div>
                </div>
            </section>

            <section id="section-steps" className="no-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <h5 className="s2">Easy Steps</h5>
                            <h2>GETTING STARTED IS EASY</h2>
                            <p className="lead">HostPallet.com makes it easy to get started with us.</p>
                            <div className="spacer-20"></div>
                        </div>
                        <div className="col-md-12 wow fadeInUp">
                            <div className="container-timeline">
                                <ul>
                                    <li>
                                        <h4>Choose a Plan</h4>
                                        <p>Pick a plan that best meets your needs. You can always upgrade/downgrade in the future.</p>
                                    </li>
                                    <li>
                                        <h4>Complete the Order Process</h4>
                                        <p>Provide your domain, enter your information and pay your invoice.</p>
                                    </li>
                                    <li>
                                        <h4>Request a Free Migration</h4>
                                        <p>Have our staff migrate your site at no charge, saving you the time of moving from a different provider.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="section-banner" className="no-bottom no-top" data-bgcolor="#DBEDF7">
                <div className="container">
                    <div className="row align-items-center g-custom-x">
                        <div className="col-lg-4 d-none d-lg-block d-xl-block  text-center">
                            <img className="relative img-fluid mt-60" src="/assets/SiteTheme/images/misc/1.png" alt="" />
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="p-sm-30 pb-sm-0 mb-sm-0">
                                <h2><span className="color-gradient">30 days</span> money back guarantee</h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="p-sm-30">
                                <div className="spacer-20 sm-hide"></div>
                                <p className="lead">You have nothing to lose by trying us out. If you are not happy, feel free to request a full refund within 30 days of signing up.</p>
                                <div className="spacer-half"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	);

};

export default SiteDirectAdminResellerComponent;

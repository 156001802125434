import AppSessionService from './appsessionservice.js';
//import { AxiosResponse, AxiosError } from 'axios';

const axios = require('axios').default;

class AuthenticationService
{
	constructor()
	{
		this.loginStatus= false;
		this.loginMessage = "";

		this.appSessionService = new AppSessionService();
	}

	async login(username, password)
	{
    	let body = { email: username, password: password };

		try
		{
			const responseData = await axios.post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_LOGIN_PATH, body);
			const data = responseData.data;
			//console.log("RESP DATA", responseData);
			//console.log("DATA", data);

			if (data.authStatus === 1)
			{
				//IF LOGGED IN
				this.loginStatus = true;
				this.loginMessage = "Logged in";

            	//STORE SECURITY TOKEN
				this.appSessionService.login(data.securityToken, data.roleRedirect);

            	//RETURN OK
				return true;
          }
          else
          {
          	//SET LOGIN MESSAGE
			this.loginStatus = false;
          	this.loginMessage = `Login Error: ${data.loginMessage}`;

            return false;
          }
		}
		catch ( error )
		{
			try
			{
				let errorJson = error.toJSON();
				//let errorMessage = error.message;
				//let errorStatus= error.status;
				if (error !== undefined && errorJson.status === 404)
				{
					this.loginStatus= false;
					this.loginMessage = `The URL ${error.url} was not found!`;
				}
				else if(errorJson.status === 401)
				{
					this.loginStatus= false;
					this.loginMessage = `Your login credentials were incorrect!`;
				}
				else
				{
					this.loginStatus= false;
					this.loginMessage = `Login Error: ${error}`;
				}
			}
			catch( errorcatch )
			{
				this.loginStatus= false;
				this.loginMessage = `Error: ${error}`;
			}

			return false;
		};

	}

	async checkAuthentication()
	{
		//let body = { security_token: this.getSecurityToken() };

		try
		{
			const responseData = await axios.get(process.env.REACT_APP_API_URL + '/api/Auth/VerifyToken/' + this.getSecurityToken());
			const data = responseData.data;

			//IF LOGGED IN
		    if (data.auth_status === 1) {
				//IF LOGGED IN
				this.loginStatus = true;

				//STORE SECURITY TOKEN
				this.appSessionService.login(data.securityToken, data.roleRedirect);

				//NAVIGATE
				//this.router.navigate([this.returnUrl]);

				//return true;
		    }

			//this.appSessionService.login(securityToken)
			//
			return true;
		}
		catch (error)
		{
			try
			{
				let errorJson = error.toJSON();
				//let errorMessage = error.message;
				//let errorStatus= error.status;

				if (error !== undefined && errorJson.status === 404)
				{
					this.loginStatus= false;
					this.loginMessage = `The URL ${error.url} was not found!`;
				}
				else
				{
					this.loginStatus= false;
					this.loginMessage = `Your login credentials were incorrect!`;
				}
			}
			catch( errorcatch )
			{
				this.loginStatus= false;
				this.loginMessage = `Error: ${error}`;
			}

			return false;
		}
	}

	getLoginStatus() { return this.appSessionService.isLoggedIn(); }
	getLoginMessage() { return this.loginMessage; }
	isLoggedIn() { return this.appSessionService.isHasSecurityToken(); }
	isHasLoginGroup() { return this.appSessionService.isHasLoginGroup(); }

	getSecurityToken() { return this.appSessionService.getSecurityToken(); }
	getLoginGroup() { return this.appSessionService.getLoginGroup(); }
	logout() { this.appSessionService.logout(); }

	forceLogin(securityToken, roleRedirect)
	{
		this.appSessionService.login(securityToken, roleRedirect);
	}
}

export default AuthenticationService;

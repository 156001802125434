

class AppSessionService
{
	constructor()
	{
		this.keepLocalCookieForMinutes = 120;	//DEFAULT 5 minutes
	}

	isHasSecurityToken() {
		if (localStorage.getItem("security_token") !== null)
			return true;

		return false;
	}

	isHasLoginGroup() {
		if (localStorage.getItem("login_group") !== null)
			return true;

		return false;
	}

	getSecurityToken() {
		if (this.isHasSecurityToken())
			return localStorage.getItem("security_token");

		return "";
	}

	getLoginGroup() {
		if (this.isHasSecurityToken())
			return localStorage.getItem("login_group");
		return "";
	}

	login(security_token, login_group) {
		let expirePeriod = new Date();
		expirePeriod.setTime((1000 * 60 * this.keepLocalCookieForMinutes) + expirePeriod.getTime());

		localStorage.setItem("security_token", security_token, expirePeriod, "/");
		localStorage.setItem("login_group", login_group, expirePeriod, "/");
	}

	logout() {
		localStorage.removeItem("security_token");
		localStorage.removeItem("login_group");
	}

	isLoggedIn() {
		return this.isHasSecurityToken() && this.isHasLoginGroup();
	}
}

export default AppSessionService;

import axios from 'axios';

import AuthenticationService from '../services/authenticationservice.js';

export const HttpIntercept = () => {
	const authenticationService = new AuthenticationService();

    axios.interceptors.request.use(request => {

		if(authenticationService.isLoggedIn())
		{
			//console.log("HTTP INTERCEPT -> OUTGOING");
			request.headers.common.Authorization = `Bearer ${authenticationService.getSecurityToken()}`;
		}

        return request;
    });
};

export default HttpIntercept;

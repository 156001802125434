import React, { useEffect } from 'react';
import {
  Link, Outlet,
} from "react-router-dom";

const importScript = src => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [src])
}

const SiteWordPressHostingComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "WordPress Hosting";
	importScript("/assets/SiteTheme/js/designesia.js");

	return (
        <div className="no-bottom no-top" id="content">
            <div id="top"></div>

            <section id="subheader" className="jarallax">
                <img src="/assets/SiteTheme/images/background/subheader.jpg" className="jarallax-img" alt="" />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>WordPress Hosting</h1>
                                </div>
                                <div className="col-lg-6 offset-lg-3">
                                    <p className="lead">Optimized hosting for WordPress sites.</p>
<span className="badge bg-primary">Use coupon code HPNEW to only pay $1.00 for your first month when ordering a monthly plan!</span>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
            </section>

            <section id="section-hero" className="no-top mt-100" aria-label="section">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <div className="switch-set">
                                <div>Monthly Plan</div>
                                <div><input id="sw-1" className="switch" type="checkbox" /></div>
                                <div>Yearly Plan</div>
                            </div>
                            <div className="spacer-10"></div>
                        </div>
                    </div>
                    <div className="spacer-10"></div>
                    <div className="row g-custom-x">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-sm-30">
                            <div className="pricing-table pricing-s1">
                                <div className="top">
                                    <h3>Copper Plan</h3>
                                </div>
                                <div className="mid">
                                    <p className="price">
                                        <span className="currency">$</span>
                                        <span className="m opt-1">10</span>
                                        <span className="y opt-2">9</span>
                                        <span className="period">per month</span>
                                    </p>
                                </div>
                                <div className="bottom">
                                    <ul>
                                        <li><i className="fa fa-check"></i><strong>5</strong> Websites</li>
                                        <li><i className="fa fa-check"></i><strong>75GB</strong> NVMe Storage</li>
                                        <li><i className="fa fa-check"></i><strong>500 GB</strong> Bandwidth</li>
										<li><i className="fa fa-check"></i><strong>Unlimited</strong> Email Accounts</li>
                                        <li><i className="fa fa-check"></i><strong>Unlimited</strong> Subdomains Accounts</li>
										<li><i className="fa fa-check"></i><strong>Unlimited</strong> FTP Accounts</li>
										<li><i className="fa fa-check"></i><strong>Unlimited</strong> MySQL Databases</li>
										<li><i className="fa fa-check"></i><strong>PHP 8.1-8.3</strong> Support</li>
										<li><i className="fa fa-check"></i><strong>24/7</strong> Customer Support</li>
										<li><i className="fa fa-check"></i><strong>2</strong> CPU Cores</li>
										<li><i className="fa fa-check"></i><strong>3</strong> GB Memory</li>
										<li><i className="fa fa-check"></i><strong>10</strong> MB/s IO</li>
										<li><i className="fa fa-check"></i><strong>750,000</strong> Inodes</li>
										<li><i className="fa fa-check"></i><strong>DirectAdmin</strong> Control Panel</li>
										<li><i className="fa fa-check"></i><strong>Softaculous</strong> Integration</li>
										<li><i className="fa fa-check"></i><strong>CloudLinux</strong> Integration</li>
										<li><i className="fa fa-check"></i><strong>JetBackup</strong> Integration</li>
                                    </ul>
                                </div>
                                <div className="action">
									<a href="https://clients.hostpallet.com/index.php?rp=/store/wordpress-hosting/copper-plan" className="btn-main">Purchase Plan</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-sm-30">
                            <div className="pricing-table pricing-s1 table-featured">
                                <div className="top">
                                    <h3>Silver Plan</h3>
                                </div>
                                <div className="mid">
                                    <p className="price">
                                        <span className="currency">$</span>
                                        <span className="m opt-1">15</span>
                                        <span className="y opt-2">14</span>
                                        <span className="period">per month</span>
                                    </p>
                                </div>
                                <div className="bottom">
                                    <ul>
                                        <li><i className="fa fa-check"></i><strong>15</strong> Websites</li>
                                        <li><i className="fa fa-check"></i><strong>125 GB</strong> NVMe Storage</li>
                                        <li><i className="fa fa-check"></i><strong>1 TB</strong> Bandwidth</li>
										<li><i className="fa fa-check"></i><strong>Unlimited</strong> Email Accounts</li>
                                        <li><i className="fa fa-check"></i><strong>Unlimited</strong> Subdomains Accounts</li>
										<li><i className="fa fa-check"></i><strong>Unlimited</strong> FTP Accounts</li>
										<li><i className="fa fa-check"></i><strong>Unlimited</strong> MySQL Databases</li>
										<li><i className="fa fa-check"></i><strong>PHP 8.1-8.3</strong> Support</li>
										<li><i className="fa fa-check"></i><strong>24/7</strong> Customer Support</li>
										<li><i className="fa fa-check"></i><strong>3</strong> CPU Cores</li>
										<li><i className="fa fa-check"></i><strong>4</strong> GB Memory</li>
										<li><i className="fa fa-check"></i><strong>15</strong> MB/s IO</li>
										<li><i className="fa fa-check"></i><strong>1,500,000</strong> Inodes</li>
										<li><i className="fa fa-check"></i><strong>DirectAdmin</strong> Control Panel</li>
										<li><i className="fa fa-check"></i><strong>Softaculous</strong> Integration</li>
										<li><i className="fa fa-check"></i><strong>CloudLinux</strong> Integration</li>
										<li><i className="fa fa-check"></i><strong>JetBackup</strong> Integration</li>
                                    </ul>
                                </div>
                                <div className="action">
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/wordpress-hosting/silver-plan" className="btn-main">Purchase Plan</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-sm-30">
                            <div className="pricing-table pricing-s1">
                                <div className="top">
                                    <h3>Gold Plan</h3>
                                </div>
                                <div className="mid">
                                    <p className="price">
                                        <span className="currency">$</span>
                                        <span className="m opt-1">20</span>
                                        <span className="y opt-2">19</span>
                                        <span className="period">per month</span>
                                    </p>
                                </div>
                                <div className="bottom">
                                    <ul>
                                        <li><i className="fa fa-check"></i><strong>30</strong> Websites</li>
                                        <li><i className="fa fa-check"></i><strong>150 GB</strong> NVMe Storage</li>
                                        <li><i className="fa fa-check"></i><strong>1.5 TB</strong> Bandwidth</li>
										<li><i className="fa fa-check"></i><strong>Unlimited</strong> Email Accounts</li>
                                        <li><i className="fa fa-check"></i><strong>Unlimited</strong> Subdomains Accounts</li>
										<li><i className="fa fa-check"></i><strong>Unlimited</strong> FTP Accounts</li>
										<li><i className="fa fa-check"></i><strong>Unlimited</strong> MySQL Databases</li>
										<li><i className="fa fa-check"></i><strong>PHP 8.1-8.3</strong> Support</li>
										<li><i className="fa fa-check"></i><strong>24/7</strong> Customer Support</li>
										<li><i className="fa fa-check"></i><strong>4</strong> CPU Cores</li>
										<li><i className="fa fa-check"></i><strong>4</strong> GB Memory</li>
										<li><i className="fa fa-check"></i><strong>20</strong> MB/s IO</li>
										<li><i className="fa fa-check"></i><strong>2,000,000</strong> Inodes</li>
										<li><i className="fa fa-check"></i><strong>DirectAdmin</strong> Control Panel</li>
										<li><i className="fa fa-check"></i><strong>Softaculous</strong> Integration</li>
										<li><i className="fa fa-check"></i><strong>CloudLinux</strong> Integration</li>
										<li><i className="fa fa-check"></i><strong>JetBackup</strong> Integration</li>
                                    </ul>
                                </div>
                                <div className="action">
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/wordpress-hosting/gold-plan" className="btn-main">Purchase Plan</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <section id="section-steps" className="no-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <h5 className="s2">Easy Steps</h5>
                        <h2>Features that brings maximum power to your website.</h2>
                        <div className="spacer-20"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <i className="icon-alarmclock de-icon mb20"></i>
                        <h4>Instant Activation</h4>
                        <p>Experience the speed of instant activation: launch your services instantly and seamlessly!</p>
                    </div>
                    <div className="col-md-3">
                        <i className="icon-refresh de-icon mb20"></i>
                        <h4>99.9% Server Uptime</h4>
                        <p>Server uptime for uninterrupted online presence and maximum business continuity.</p>
                    </div>
                    <div className="col-md-3">
                        <i className="icon-layers de-icon mb20"></i>
                        <h4>Dallas, TX Data Center</h4>
                        <p>Host your sites on the backbone of the internet.</p>
                    </div>
                    <div className="col-md-3">
                        <i className="icon-cloud de-icon mb20"></i>
                        <h4>Daily Backups</h4>
                        <p>Empowering you with daily data assurance and securing your peace of mind.</p>
                    </div>
                </div>
            </div>
        </section>



            <section id="section-steps" className="no-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <h2>Why our WordPress hosting plans?</h2>
                            <p className="lead"></p>
                            <div className="spacer-20"></div>
                        </div>
                        <div className="col-md-12 wow fadeInUp">
                            <div className="container-timeline">
Our WordPress hosting plans are tailored around speed. While many providers, including us, use LiteSpeed and LSCache to speed up sites, in many cases this is not enough. The issue with WordPress is that the site is heavy, and while caching plugins can help, all too often these cache plugins can only do so much good. We've spent months building a solution to this issue. We've built large numbers of caching rules implemented through Varnish Cache. Varnish Cache is a software application that runs and converts sites to static content. It then serves the site pages from cache as well. This means your WordPress site does not require any back-end queries to run, and is served instantly, drastically speeding up your time to first byte that really determines the speed of your site to the end user.<br />
<br />
Interesting in seeing the performance of one of the sites on our WordPress hosting plans? Take a look at our demo site at 
<p>
<a href="https://myowndemostore.com" target="_NEW">https://myowndemostore.com</a>
</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section>
                <div className="container">
                    <div className="row g-custom-x">
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-alarmclock"></i>
                                <div className="text">
                                    <h4>Malware Scans and Cleanup</h4>
Hackers use malware to steal your customers data, deface your site and ruin your reputation. Our malware scans run in real time to attempt to prevent any damage they can cause. We maintain large databases of malware signatures, updated constantly to better protect our customers.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-profile-male"></i>
                                <div className="text">
                                    <h4 className="">Speed and Performance Optimized</h4>
									Our servers are fully optimized for WordPress. Tuned for high performance LiteSpeed/LSCache requests, hosted on high end E5 servers with large numbers of CPU cores, we can easily assure you of speed and performance. Integrated with varnish for faster performance.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-refresh"></i>
                                <div className="text">
                                    <h4>24/7 Support Team</h4>
									We have staff to help you with hard to investigate PHP errors, plugins causing your site to not load and much more at all times of the day. Most hosts staff are not able to help with simple WordPress issues, but we've trained all our staff to have the skills to help. Help is just one ticket away!
                                </div>
                            </div>
                        </div>

 
                         <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-tools-2"></i>
                                <div className="text">
                                    <h4>One Click Install</h4>
									Easy install, clone or stage a WordPress install with one click through our WordPress Installer.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-layers"></i>
                                <div className="text">
                                    <h4 className="">WordPress Backups and Restores</h4>
									We perform daily backups of all customers sites. You can easily restore any particular site with our control panel.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb30">
                            <div className="d-card">
                                <i className="icon-shield"></i>
                                <div className="text">
                                    <h4>Satisfaction Guaranteed</h4>
									Not happy with the quality of our services? We provide all new clients with a 30 day money back guarantee.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section id="section-steps" className="no-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <h5 className="s2">Easy Steps</h5>
                            <h2>GETTING STARTED IS EASY</h2>
                            <p className="lead">HostPallet.com makes it easy to get started with us.</p>
                            <div className="spacer-20"></div>
                        </div>
                        <div className="col-md-12 wow fadeInUp">
                            <div className="container-timeline">
                                <ul>
                                    <li>
                                        <h4>Choose a Plan</h4>
                                        <p>Pick a plan that best meets your needs. You can always upgrade/downgrade in the future.</p>
                                    </li>
                                    <li>
                                        <h4>Complete the Order Process</h4>
                                        <p>Provide your domain, enter your information and pay your invoice.</p>
                                    </li>
                                    <li>
                                        <h4>Request a Free Migration</h4>
                                        <p>Have our staff migrate your site at no charge, saving you the time of moving from a different provider.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="section-banner" className="no-bottom no-top" data-bgcolor="#DBEDF7">
                <div className="container">
                    <div className="row align-items-center g-custom-x">
                        <div className="col-lg-4 d-none d-lg-block d-xl-block  text-center">
                            <img className="relative img-fluid mt-60" src="/assets/SiteTheme/images/misc/1.png" alt="" />
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="p-sm-30 pb-sm-0 mb-sm-0">
                                <h2><span className="color-gradient">30 days</span> money back guarantee</h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="p-sm-30">
                                <div className="spacer-20 sm-hide"></div>
                                <p className="lead">You have nothing to lose by trying us out. If you are not happy, feel free to request a full refund within 30 days of signing up.</p>
                                <div className="spacer-half"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	);

};

export default SiteWordPressHostingComponent;

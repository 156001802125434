import axios from 'axios';

import AuthenticationService from '../services/authenticationservice.js';

export const ErrorIntercept = () => {
	const authenticationService = new AuthenticationService();

    axios.interceptors.response.use(null, (error) => {

        const { response } = error;
        if (!response) {
            // network error
            console.error(error);
            return Promise.reject(error);
        }
    
		//IF 401 -> DO LOGOUT
        if ([401].includes(response.status)) {
            authenticationService.logout();
			//console.log("INTERCEPT HTTP =? DO LOGOUT");
			window.location.href="/auth/login";
        }

        //const errorMessage = response.data?.message || response.statusText;
        //console.error('ERROR:', errorMessage);

		return Promise.reject(error);
    });
};

export default ErrorIntercept;


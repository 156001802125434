import {
	useLocation,
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

//HEADER + FOOTER + MISC
import SiteHeaderComponent from './pages/siteheader.js';
import SiteFooterComponent from './pages/sitefooter.js';
import ProtectedRoute from './helpers/protectedroute.js';
//import SiteComponent from './pages/sitecomponent.js';

//SITE
import SiteIndexComponent from './pages/site/siteindex.js';
import SitecPanelSharedHostingComponent from './pages/site/cpanelsharedhosting.js';
import SiteDirectAdminSharedHostingComponent from './pages/site/directadminsharedhosting.js';

import SiteAUPComponent from './pages/site/siteaup.js';
import SiteTOSComponent from './pages/site/sitetos.js';
import SitecPanelResellerComponent from './pages/site/sitecpanelresellerhosting.js';
import SiteDirectAdminResellerComponent from './pages/site/sitedirectadminresellerhosting.js';
import SiteWordPressHostingComponent from './pages/site/sitewordpresshosting.js';
import SiteVpsHostingComponent from './pages/site/sitevpshosting.js';
import SiteFaqComponent from './pages/site/sitefaq.js';
import SiteContactComponent from './pages/site/sitecontact.js';

const NoMatchComponent = () => {
  return (
    <div>
      <h2>404!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};

function App() {

	return (
	<div id="wrapper">
		<div id="de-loader"></div>

		<Router>
			<SiteHeaderComponent />
			<Routes>

				<Route path="aup" element={<SiteAUPComponent />} />
				<Route path="tos" element={<SiteTOSComponent />} />

				<Route path="cpanel-shared-hosting" element={<SitecPanelSharedHostingComponent />} />
				<Route path="directadmin-shared-hosting" element={<SiteDirectAdminSharedHostingComponent />} />

				<Route path="cpanel-whm-reseller-hosting" element={<SitecPanelResellerComponent />} />
				<Route path="directadmin-reseller-hosting" element={<SiteDirectAdminResellerComponent />} />

				<Route path="wordpress-hosting" element={<SiteWordPressHostingComponent />} />

				<Route path="faq" element={<SiteFaqComponent />} />
				<Route path={`contact`} element={<SiteContactComponent />}></Route>

				<Route exact path="/" element={<SiteIndexComponent />}></Route>
				<Route path="*" element={<NoMatchComponent />}></Route>

			</Routes>

			<a href="#" id="back-to-top"></a>
			<SiteFooterComponent />
		</Router>
	</div>
	);
}

export default App;

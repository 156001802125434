import React, { Component, useEffect, useReducer, useState } from 'react';

const importScript = src => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [src])
}

const SiteAUPComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "AUP";
	importScript("/assets/SiteTheme/js/designesia.js");

	return (
  <div className="no-bottom no-top" id="content">
	<div id="top"></div>

	<section id="subheader" className="jarallax">
                <img src="/assets/SiteTheme/images/background/subheader.jpg" className="jarallax-img" alt="" />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>Acceptable Use Policy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
	</section>

This Acceptable Use Policy ("AUP") governs your use of the Services and is incorporated by reference into HostPallet's Terms of Service. Unless otherwise stated, defined terms in this AUP have the same meaning as provided in the Terms of Service. HostPallet may modify this AUP at any time without notice.<br />
<br />

The Services provided by HostPallet may only be used for lawful purposes. You agree to comply with all applicable laws, rules and regulations in connection with your use of the Services. HostPallet reserves the right to refuse service to anyone at our sole discretion. Any material or conduct that in our judgment violates this AUP in any manner may result in suspension or termination of the Services or removal of content with or without notice.<br />
<br />
Failure to respond to a communication from our abuse department within the specified time period in our communication to you may result in the suspension or termination of the Services.<br />
<br />
<br />
<strong>Prohibited Uses</strong><br />

        You may not directly or indirectly use the Services in connection with any of the following, as determined in HostPallet's sole discretion:

            You may not use the Services to publish content or engage in activity that is illegal under applicable law, that is harmful to others, or that would subject HostPallet to liability, including, without limitation, in connection with any of the following, each of which is prohibited under this AUP:

                Disclosing sensitive personal information about others;

                Storing personal or sensitive information, including without limitation, "Protected Health Information" as defined under the U.S. Health Insurance Portability and Accountability Act (HIPAA);

                Phishing or engaging in identity theft;

                Distributing computer viruses, worms, Trojan horses or other malicious code;

                Promoting or facilitating prostitution or sex trafficking;

                Hosting ponzi or pyramid schemes, or websites that engage in unlawful or deceptive marketing practices;

                Hosting, distributing or linking to child sexual abuse material (CSAM) or content that is harmful to minors (CSAM will be suspended immediately without notice and reported to law enforcement or the National Center for Missing and Exploited Children);

                Engaging in the unlawful distribution of controlled substances, drug contraband or prescription medications (including without limitation, promotion, marketing, or sale of prescription medications without a valid prescription);

                Gambling;

                Promoting or facilitating violence or terrorist activities; or

                Infringing the intellectual property or other proprietary rights of others.

Excessive Use of Resources or Network Abuse
            You may not consume excessive amounts of server or network resources or use the Services in any way which results in server performance issues or which interrupts service for other customers. Prohibited activities that contribute to excessive use, include without limitation:

                Hosting or linking to an anonymous proxy server;

                Operating a file sharing site;

                Hosting scripts or processes that adversely impact our systems; or

                Utilizing software that interfaces with an Internet Relay Chat (IRC) network.

Unauthorized System Access
            You may not directly or indirectly use the Services to gain access to any network or system without permission, including without limitation

                Accessing another network without permission, including to probe or scan for vulnerabilities or breach security or authentication measures;

                Attacking other networks (e.g., utilizing botnets or other means to launch Denial of Service (DoS) or other attacks);

                Intercepting or monitoring data without permission; or

                Using any deep-link, page-scrape, robot, crawl, index, spider, offline reader, click spam, macro programs, internet agent, or other automatic device, program, algorithm or methodology, to use, access, copy, index, acquire information, generate impressions or clicks, input information, store information, search, generate searches, or monitor any portion of HostPallet's website or servers for any unauthorized purpose.<br />
<br />

<strong>Storage of Backups</strong><br />
            You may not use your hosting account as a backup solution. Our Services are designed to host your website only and may not be used as a data repository. HostPallet reserves the right to remove backups from your hosting account with or without notice.<br />
<br />

<strong>Zero Tolerance Spam Policy</strong><br />
                Any user account which causes or results in our IP space being blacklisted will be immediately suspended and/or terminated.

                Forging or misrepresenting message headers is prohibited.

                Websites advertised via spam (i.e. "Spamvertised") may not be hosted on our servers.

                You may not use the Services to sell contact lists or send email to any purchased lists (i.e. \u201cSafe Lists\u201d).

                You may not use the Services to send spam or bulk unsolicited messages. We may terminate with or without notice the account of any user who sends spam.<br />
<br />

<strong>Enforcement</strong><br />
        Your Services may be suspended or terminated with or without notice upon any violation of this AUP. Any violations may result in the immediate suspension or termination of your account.

        HostPallet reserves the right to disable or remove any content which is prohibited by this AUP, including to prevent harm to others or to HostPallet or the Services, as determined in HostPallet's sole discretion.

        Violations will be reported to law enforcement as determined by HostPallet in its sole discretion.

        A failure to respond to an email from our abuse team within forty-eight (48) hours, or as otherwise specified in the communication to you, may result in the suspension or termination of your Services.

        Websites hosted on HostPallet's servers in the U.S. are generally governed by U.S. law. As a web host, HostPallet is not the publisher of user generated content and we are not in a position to investigate the veracity of individual defamation claims. Rather, we rely on the legal system and courts to determine whether or not material is defamatory. In any case in which a court of competent jurisdiction, as determined in HostPallet's sole discretion, orders material to be removed, HostPallet will disable access to such material.

        Notwithstanding the foregoing, HostPallet users are responsible for using the Services in compliance with applicable laws in their jurisdiction, including without limitation, any laws relating to defamation, unsolicited emails, spamming, privacy, obscenity, and intellectual property infringement. HostPallet reserves the right to take local legal considerations into account and to respond accordingly in HostPallet's sole discretion.

        Enforcement of this AUP is at HostPallet's sole discretion.<br />
<br />

<strong>Reporting Violations</strong><br />
To report a violation of HostPallet's AUP or infringement of intellectual property, please contact us.<br />
<br />
This page was last modified on 09/01/2024


  </div>
	);

};

export default SiteAUPComponent;

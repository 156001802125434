import React, { Component, useEffect, useReducer, useState } from 'react';
import {
  useLocation, useNavigate, useParams
} from "react-router-dom";

const importScript = src => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [src])
}

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import AlertComponent from '../alertcomponent.js';
import HttpClient from '../../helpers/httpclient.js';

const SiteContactComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Contact";
	importScript("/assets/SiteTheme/js/designesia.js");

	//REACT HOOK FORM INIT
	const { control, handleSubmit, register, reset, formState: { errors } } = useForm({
		mode: "onChange",
    	defaultValues:
		{
		}
	});

	const [state, setState] = useState({
			showAlert: false,
			responseStatus: false,
			responseMessage: "",
			apiRequestLoadOK: false,
			isPageLoaded: false,
			isAPIFailure: false,
			formSubmitted: false
		});

	const navigate = useNavigate();

	useEffect(() => {


	}, []);

	const getFormValidStatus = (e) => {
		return formState.isFormValid;
	}

	const onSubmit = async (values) => {

		let data = {
        "name": values.name,
        "email": values.email,
        "message": values.message
		}

		const httpClient = new HttpClient();

		try
		{
			const responseData = await httpClient.sendPost('/sendmail.php', data);
			//responseData.data is the TEXT STRING. "OK" or "FAIL"
			//console.log("responseData.data", responseData.data);

			//IF DID NOT SUBMIT PROPERLY
			if (responseData.data.status !== undefined && !responseData.data.status) {
				setState({...state, showAlert: true, responseStatus: false, responseMessage: `Error: ${responseData.data.message}`});
				//window.scrollTo(0, 0);
			}
			else {
				setState({...state, showAlert: true, responseStatus: true, formSubmitted: true, responseMessage: `${responseData.data.message}`});
				//window.scrollTo(0, 0);
			}

		}
		catch ( error )
		{
			setState({...state, showAlert: true, responseStatus: false, responseMessage: "Failed to Process E=" + error});
			//window.scrollTo(0, 0);

			return false;
		};

		setState({...state, formSubmitted: true});
	}

	return (
        <div className="no-bottom no-top" id="content">

            <div id="top"></div>
            
            <section id="subheader" className="jarallax">
                <img src="/assets/SiteTheme/images/background/subheader.jpg" className="jarallax-img" alt="" />
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
									<h1>Contact Us</h1>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
            </section>
           

            <section aria-label="section">
                <div className="container">
					<div className="row g-custom-x">
							
						<div className="col-lg-8 mb-sm-30">

							<h3>Do you have any question?</h3>
							
							{!state.formSubmitted && (
							<form name="contactForm" id="contact_form" className="form-border" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-4 mb10">
										Name:
                                        <div className="field-set">

										<input type="text" className="form-control" placeholder="Your Name"
										{...register("name", {
											required: "The name field must be between 4 and 255 characters!",
											minLength: 4,
											maxLength: 255
										})}
										/>

										{errors.name && (
										<p className="text-danger">
										{errors.name.message}
										</p>
										)}

                                        </div>
                                    </div>

                                    <div className="col-md-4 mb10">
										Email: 
                                        <div className="field-set">

										<input type="text" className="form-control" placeholder="Your Email"
										{...register("email", {
											required: "A valid Email Address is required",
											pattern: {
												value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
												message: "A valid Email Address is required"
											}
										})}
										/>

										{errors.email && (
										<p className="text-danger">
											{errors.email.message}
										</p>
										)}
                                        </div>
                                    </div>

                                </div>
                                    
                                <div className="field-set mb20">
									Message: 
								  <textarea rows="7" cols="25" className="form-control" id="message" placeholder="Your Message" 
									name="message"
									{...register("message", {
										required: "The message field must be between 4 and 1024 characters!",
										minLength: 4,
										maxLength: 1024
									})}
									></textarea>

									{errors.message && (
									<p className="text-danger">
										{errors.message.message}
									</p>
									)}

                                </div>

                                <div id='submit' className="mt20">
									<input type="submit" value="Send Message" className="btn-main" />
                                </div>

								<AlertComponent showAlert={state.showAlert} messageType={state.responseStatus} messageText={state.responseMessage} />

                            </form>
							)}
							{state.formSubmitted && (
							<div>
								Your message has been sent successfully.
							</div>
							)}

						</div>
						
						<div className="col-lg-4">

							<div className="padding40 box-rounded mb30" data-bgcolor="#F2F6FE">
								<h4>Contact Us</h4>
								<address className="s1">
									<span><i className="id-color fa fa-envelope-o fa-lg"></i><img src="/assets/SiteTheme/images/contact_sales.png" /></span>
									<span><i className="id-color fa fa-file-pdf-o fa-lg"></i><img src="/assets/SiteTheme/images/contact_support.png" /></span>
								</address>
							</div>

						</div>
							
					</div>
				</div>
			</section>
        </div>
    );
};

export default SiteContactComponent;

//import { AxiosResponse, AxiosError } from 'axios';

const axios = require('axios').default;

class HttpClient
{
	constructor()
	{

	}

	async sendGet(url)
	{
		return await axios.get(process.env.REACT_APP_API_URL + url);
	}

	async sendPost(url, data)
	{
    	let body = data;
		return await axios.post(process.env.REACT_APP_API_URL + url, body);
	}
}

export default HttpClient;

import React, { useEffect } from 'react';
import {
  Link, Outlet,
} from "react-router-dom";

const importScript = src => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [src])
}

const SiteFaqComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "FAQ";
	importScript("/assets/SiteTheme/js/designesia.js");

	useEffect(() => {

	}, []);

	const getFormValidStatus = (e) => {
		return formState.isFormValid;
	}

	return (
        <div className="no-bottom no-top" id="content">
            <div id="top"></div>

			<section data-bgimage="" className="no-bottom" data-stellar-background-ratio=".5">
            	<div className="container">
					<div className="row align-items-center">
						<div className="col-md-8 offset-md-2 text-center">
							<div className="spacer-double sm-hide">
							</div>

							<h2>Help Center</h2>
							<div className="spacer-20"></div>

						</div>
					</div>
				</div>
			</section>

        <section id="section-features">
                <div className="container">
                    <div className="row g-5">

                        <div className="col-md-6">
                            <div className="box-highlight">
                                        <div className="heading"><h4>Sales/Billing</h4></div>
                                        <div className="content">
                                            
                                            <div className="accordion">
                                <div className="accordion-section">
                                    <div className="accordion-section-title" data-tab="#accordion-1">
                                        How long does it take to have my account setup after ordering?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-1">
                                        <p>Your account should be setup instantly, as long as you have passed our fraud checks. You should receive your welcome email within 5 minutes of payment being made.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-3">
                                        Do you offer dedicated IP's?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-3">
                                        <p>We do not offer dedicated IP's on our Shared/Reseller/WordPress Hosting plans.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-4">
                                        What payment methods do you accept?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-4">
                                        <p>We accept Bitcoin and Zelle.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-5">
                                        What is your cancellation policy?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-5">
                                        <p>If you would like to cancel, please let us know 24 hours before your billing due date, and ensure to cancel any recurring subscriptions.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-6">
                                        Where are your servers located?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-6">
                                        <p>At this time all our servers are located in Dallas, TX (USA).</p>
                                    </div>
                                </div>
                            </div>
                                            
                                        </div>
                                    </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="box-highlight s2">
                                        <div className="heading"><h4>Products and Services</h4></div>
                                        <div className="content">
                                            
                                            <div className="accordion">
                                <div className="accordion-section">
                                    <div className="accordion-section-title" data-tab="#accordion-b-1">
                                        What control panels are you using?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-1">
                                        <p>We offer DirectAdmin for our shared/reseller plans. The WordPress plans use DirectAdmin currently, but we are working on our own custom panel for WordPress hosting.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-b-3">
                                        How often are backups taken?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-3">
                                        <p>We take backups once a week on our shared/reseller hosting plans. Our WordPress hosting plans have backups taken every day. We tend to retain backups for 1-2 weeks at most.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-b-4">
                                        What software integrations are available?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-4">
                                        <p>All our servers have Softaculous, JetBackup and CloudLinux.</p>
                                    </div>
                                    <div className="accordion-section-title" data-tab="#accordion-b-5">
                                        What technology powers the WordPress plans?
                                    </div>
                                    <div className="accordion-section-content" id="accordion-b-5">
                                        <p>We are using DirectAdmin and LiteSpeed/LSCache, integrating with our own custom backend tools. We've worked extensively to integrate DirectAdmin Varnish with our own custom Varnish rules. Varnish integration includes our own custom database integration to detect write requests causing Varnish cache to be flushed automatically. These features along with others are currently in beta and can be enabled upon request.</p>
                                    </div>

                                </div>
                            </div>
                                            
                                        </div>
                                    </div>
                        </div>

                        <div className="col-md-6">
                            <div className="box-highlight">
                                        <div className="heading"><h4>Support</h4></div>
                                        <div className="content">
                                            
                                            <div className="accordion">
								                <div className="accordion-section">
								                    <div className="accordion-section-title" data-tab="#accordion-a1">
								                        What are the hours of your support team?
								                    </div>
								                    <div className="accordion-section-content" id="accordion-a1">
								                        <p>Our support team is available 24 hour a day, 7 days a week.</p>
								                    </div>
								                    <div className="accordion-section-title" data-tab="#accordion-a2">
								                        How long does it take your support team to respond to tickets?
								                    </div>
								                    <div className="accordion-section-content" id="accordion-a2">
								                        <p>The amount of time will vary depending on the day, and the number of tickets in queue. On average it can take 60-120 minutes.</p>
								                    </div>
								                    <div className="accordion-section-title" data-tab="#accordion-a3">
								                        What level of support do you offer?
								                    </div>
								                    <div className="accordion-section-content" id="accordion-a3">
								                        <p>We are happy to help with a number of issues customers may face. We gladly assist customers with using our control panel and all of the features available within it. We are more than happy to help investigate why your site is not loading, showing a white page, issues with 404/500/503 pages, or slow. We do not however support site development, such as helping with CSS, JS development issues, making changes to themes or site pages and other web development tasks.</p>
								                    </div>
								                </div>
						                </div>
                                            
									</div>
								</div>
							</div>
                    	</div>
                	</div>
            </section>
            
        </div>
    );
};

export default SiteFaqComponent;

import React from 'react';
import {
  Link, Outlet,
} from "react-router-dom";

const SiteFooterComponent = (props) => {

	return (
	<div>
        <a href="#" id="back-to-top"></a>

        <footer className="footer-light">
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col">
									<Link className="menu-item" to={`/`}>
										Copyright 2024 - HostPallet.com. All Rights Reserved.
									</Link>
									<span className="menu-item">
									Part of the IronHostGroup / Algorithm Network.
									</span>
                                </div>

                                <div className="social-icons">

                                </div>

                                <ul className="menu-simple">
									<img className="img-card-sm" src="/assets/SiteTheme/images/payments-logo/zelle.png" alt="" />
									<img className="img-card-sm" src="/assets/SiteTheme/images/payments-logo/bitcoin.png" alt="" />

                                    <li> <Link className="menu-item" to={`/tos`}>Terms Of Service</Link> </li>
                                    <li> <Link className="menu-item" to={`/aup`}>Acceptable Use Policy</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
	</div>
	);

};

export default SiteFooterComponent;

const AlertComponent = props => {
  const { showAlert, messageType, messageText } = props;

  return (
	<div>
	{showAlert &&
	<div className={messageType ? 'alert alert-success' : 'alert alert-danger'}>
	  {messageText}
	</div>
	}

	</div>
  );
};

export default AlertComponent;

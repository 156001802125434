import React, { Component, useEffect } from 'react';
import {
  Link, useLocation, useNavigate, useParams
} from "react-router-dom";

const importScript = src => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [src])
}

const SiteIndexComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Home";
	importScript("/assets/SiteTheme/js/designesia.js");
	const navigate = useNavigate();

	const doNav = async (link) => {
		window.scrollTo(0, 0);
		navigate(`/directadmin-shared-hosting`);
		return false;
	}

	return (
        <div className="no-bottom no-top" id="content">
            <div id="top"></div>
            <section id="section-hero" aria-label="section" data-bgimage='url(/assets/SiteTheme/images/background/1.jpg) bottom'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h1>High performance hosting for everyone <span className="color-gradient">small</span> or <span className="color-gradient">large</span></h1>
                            <p className="lead">High performance secure hosting for your website. Don't lose anymore visitors due to the slow speed of your hosting provider.</p>
                            <div className="spacer-double"></div>
                        </div>
                        <div className="col-md-6 xs-hide" >
                            <img src="/assets/SiteTheme/images/misc/server-2.png" className="lazy img-fluid anim-up-down" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="no-top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 wow fadeInUp">
                            <img className="img-fluid anim-up-down" src="/assets/SiteTheme/images/misc/server.png" alt="" />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                            <div className="p-sm-30 pb-sm-0 mb-sm-0">
                                <h2>HostPallet.com's fast servers bring maximum <span className="color-gradient">performance</span> to your website.</h2>
                                <p>HostPallet.com offers reliable web hosting solutions. We empower your online presence with unparalleled performance, exceptional support, and hassle-free scalability.</p>
                                <div className="row">
                                    <div className="col-md-5">
                                        <ul className="ul-style-2">
                                            <li><h4>Instant Activation</h4></li>
                                            <li><h4>99.9% Uptime</h4></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-5">
                                        <ul className="ul-style-2">
                                            <li><h4>Reliable Hardware</h4></li>
                                            <li><h4>24 / 7 Support</h4></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="spacer-half"></div>
								<a className="btn-main" onClick={() => doNav(`/directadmin-shared-hosting`)}>Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="section-pricing" className="no-top" data-bgimage='url(/assets/SiteTheme/images/background/1.jpg) top'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="text-center">
                                <h5 className="s2">Hosting Plan</h5>
                                <h2>Choose Perfect <span className="id-color">Web Hosting</span> Package For You</h2>
                                <div className="spacer-20"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <div className="switch-set">
                                <div>Monthly Plan</div>
                                <div><input id="sw-1" className="switch" type="checkbox" /></div>
                                <div>Yearly Plan</div>
                                <div className="spacer-20"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row de_pricing-tables shadow-soft g-0">
                        <div className="col-xl-3 col-lg-6">
                            <div className="de_pricing-table type-2">
                                <div className="d-head">
                                    <h3>Cardboard Plan</h3>
                                    <p>Ideal solution for beginners.</p>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$6<span>/mo</span></h4>
                                    <h4 className="opt-2">$5<span>/mo</span></h4>
                                </div>
                                <div className="d-action">
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/directadmin-shared-hosting/cardboard-plan" className="btn-main opt-1 w-100">Get Monthly Plan</a>
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/directadmin-shared-hosting/cardboard-plan" className="btn-main opt-2 w-100">Get Yearly Plan</a>
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
										<li>5 Websites</li>
                                        <li>75 GB NVMe Storage</li>
										<li>500 GB Bandwidth</li>
										<li>Unlimited Email Accounts</li>
										<li>Unlimited Subdomains</li>
										<li>Unlimited FTP Accounts</li>
										<li>Unlimited MySQL Databases</li>
										<li>Supports PHP 7.4, 8.1-8.3</li>
										<li>24/7 Customer Support</li>
										<li>2 CPU Cores</li>
										<li>2 GB Memory</li>
										<li>5 MB/s IO</li>
										<li>500,000 Inodes</li>
										<li>DirectAdmin Control Panel</li>
                                    </ul>
                                </div>
                                <div className="d-group">
                                    <h4>Also Includes</h4>
                                    <ul className="d-list">
                                        <li>Free Backups</li>
                                        <li>Free SSL Certificates</li>
                                        <li>Free Migration Assistance</li>
										<li>Softaculous Integration</li>
										<li>CloudLinux Integration</li>
										<li>JetBackup Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="de_pricing-table type-2">
                                <div className="d-head">
                                    <h3>Plastic Plan</h3>
                                    <p>For those need to running multiple sites.</p>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$12<span>/mo</span></h4>
                                    <h4 className="opt-2">$11<span>/mo</span></h4>
                                </div>
                                <div className="d-action">
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/directadmin-shared-hosting/plastic-plan" className="btn-main opt-1 w-100">Get Monthly Plan</a>
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/directadmin-shared-hosting/plastic-plan" className="btn-main opt-2 w-100">Get Yearly Plan</a>
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
										<li>10 Websites</li>
                                        <li>100 GB NVMe Storage</li>
										<li>1000 GB Bandwidth</li>
										<li>Unlimited Email Accounts</li>
										<li>Unlimited Subdomains</li>
										<li>Unlimited FTP Accounts</li>
										<li>Unlimited MySQL Databases</li>
										<li>Supports PHP 7.4, 8.1-8.3</li>
										<li>24/7 Customer Support</li>
										<li>2 CPU Cores</li>
										<li>3 GB Memory</li>
										<li>10 MB/s IO</li>
										<li>750,000 Inodes</li>
										<li>DirectAdmin Control Panel</li>
                                    </ul>
                                </div>
                                <div className="d-group">
                                    <h4>Also Includes</h4>
                                    <ul className="d-list">
                                        <li>Free Backups</li>
                                        <li>Free SSL Certificates</li>
                                        <li>Free Migration Assistance</li>
										<li>Softaculous Integration</li>
										<li>CloudLinux Integration</li>
										<li>JetBackup Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6">
                            <div className="de_pricing-table type-2">
                                <div className="d-recommend">Recommended</div>
                                <div className="d-head">
                                    <h3>Copper WordPress Plan</h3>
                                    <p>A entry level WordPress customized plan.</p>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$10<span>/mo</span></h4>
                                    <h4 className="opt-2">$9<span>/mo</span></h4>
                                </div>
                                <div className="d-action">
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/wordpress-hosting/copper-plan" className="btn-main opt-1 w-100">Get Monthly Plan</a>
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/wordpress-hosting/copper-plan" className="btn-main opt-2 w-100">Get Yearly Plan</a>
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
										<li>5 Websites</li>
                                        <li>75 GB NVMe Storage</li>
										<li>500 GB Bandwidth</li>
										<li>Unlimited Email Accounts</li>
										<li>Unlimited Subdomains</li>
										<li>Unlimited FTP Accounts</li>
										<li>Unlimited MySQL Databases</li>
										<li>Supports PHP 7.4, 8.1-8.3</li>
										<li>24/7 Customer Support</li>
										<li>2 CPU Cores</li>
										<li>3 GB Memory</li>
										<li>10 MB/s IO</li>
										<li>750,000 Inodes</li>
										<li>DirectAdmin Control Panel</li>
                                    </ul>
                                </div>
                                <div className="d-group">
                                    <h4>Also Includes</h4>
                                    <ul className="d-list">
                                        <li>Free Backups</li>
                                        <li>Free SSL Certificates</li>
                                        <li>Free Migration Assistance</li>
										<li>Softaculous Integration</li>
										<li>CloudLinux Integration</li>
										<li>JetBackup Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6">
                            <div className="de_pricing-table type-2">
                                <div className="d-head">
                                    <h3>Silver WordPress Plan</h3>
                                    <p>A WordPress customized plan.</p>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$15<span>/mo</span></h4>
                                    <h4 className="opt-2">$14<span>/mo</span></h4>
                                </div>
                                <div className="d-action">
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/wordpress-hosting/silver-plan" className="btn-main opt-1 w-100">Get Monthly Plan</a>
                                    <a href="https://clients.hostpallet.com/index.php?rp=/store/wordpress-hosting/silver-plan" className="btn-main opt-2 w-100">Get Yearly Plan</a>
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
										<li>15 Websites</li>
                                        <li>125 GB NVMe Storage</li>
										<li>1000 GB Bandwidth</li>
										<li>Unlimited Email Accounts</li>
										<li>Unlimited Subdomains</li>
										<li>Unlimited FTP Accounts</li>
										<li>Unlimited MySQL Databases</li>
										<li>Supports PHP 7.4, 8.1-8.3</li>
										<li>24/7 Customer Support</li>
										<li>3 CPU Cores</li>
										<li>4 GB Memory</li>
										<li>15 MB/s IO</li>
										<li>1,500,000 Inodes</li>
										<li>DirectAdmin Control Panel</li>
                                    </ul>
                                </div>
                                <div className="d-group">
                                    <h4>Also Includes</h4>
                                    <ul className="d-list">
                                        <li>Free Backups</li>
                                        <li>Free SSL Certificates</li>
                                        <li>Free Migration Assistance</li>
										<li>Softaculous Integration</li>
										<li>CloudLinux Integration</li>
										<li>JetBackup Integration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="section-testimonial" className="no-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <h5 className="s2">Testimonials</h5>
                            <h2>Trusted by happy customers <span className="id-color">worldwide</span></h2>
                        </div>
                        <div className="owl-carousel owl-theme wow fadeInUp" id="testimonial-carousel">
                            <div className="item">
                                <div className="de_testi type-2">
                                    <blockquote>
                                        <h4>Good Loading Speed</h4>
                                        <p>I moved to HostPallet from an EIG brand, I left due to the slow loading time of my website. I signed up for one of the WordPress hosting plans and cannot believe how fast my site loads.
                                        </p>
                                        <div className="de_testi_by">
                                            <span>Frank</span>
                                        </div>
                                    </blockquote>
                                </div>
                            </div>
                            <div className="item">
                                <div className="de_testi type-2">
                                    <blockquote>
                                        <h4>Keeps Backups</h4>
                                        <p>My last provider had a RAID array failure, causing me to leave them as my site was down for over two days. They  claimed they keep backups but it appears not and I had to use my old backup that were a few weeks old. I like HostPallet very much for their help in getting my sites online which was an issue as I was missing data. The best part is HostPallet uses JetBackup, so I can see that backups do exist.</p>
                                        <div className="de_testi_by">
                                            <span>Abdul</span>
                                        </div>
                                    </blockquote>
                                </div>
                            </div>
                            <div className="item">
                                <div className="de_testi type-2">
                                    <blockquote>
                                        <h4>Happy with Support</h4>
                                        <p>I updated my WordPress site and all of a sudden my site was no longer working. I contacted HostPallet's support team and they identified that the new WordPress plugin I updated to was conflicting with another. They helped me quickly downgrade my plugin and were able to get me online quickly.</p>
                                        <div className="de_testi_by">
                                            <span>Raul</span>
                                        </div>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="section-steps" className="no-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <h5 className="s2">Easy Steps</h5>
                            <h2>How to get started</h2>
                            <p className="lead">The process is easier than you can imagine.</p>
                            <div className="spacer-20"></div>
                        </div>
                        <div className="col-md-12 wow fadeInUp">
                            <div className="container-timeline">
                                <ul>
                                    <li>
                                        <h4>Choose a Plan</h4>
                                        <p>Pick a plan that best meets your needs. You can always upgrade/downgrade in the future.</p>
                                    </li>
                                    <li>
                                        <h4>Complete the Order Process</h4>
                                        <p>Provide your domain, enter your information and pay your invoice.</p>
                                    </li>
                                    <li>
                                        <h4>Request a Free Migration</h4>
                                        <p>Have our staff migrate your site at no charge, saving you the time of moving from a different provider.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="section-banner" className="no-bottom no-top" data-bgcolor="#DBEDF7">
                <div className="container">
                    <div className="row align-items-center g-custom-x">
                        <div className="col-lg-4 d-none d-lg-block d-xl-block  text-center">
                            <img className="relative img-fluid mt-60" src="/assets/SiteTheme/images/misc/1.png" alt="" />
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="p-sm-30 pb-sm-0 mb-sm-0">
                                <h2><span className="color-gradient">30 days</span> money back guarantee</h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="p-sm-30">
                                <div className="spacer-20 sm-hide"></div>
                                <p className="lead">You have nothing to lose by trying us out. If you are not happy, feel free to request a full refund within 30 days of signing up.</p>
                                <div className="spacer-half"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	);

};

export default SiteIndexComponent;
